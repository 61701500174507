export const registerErrorReporting = () => {
  // Skip error reporting on legacy browsers
  // (which don’t support Object.fromEntries).
  if (!Object.fromEntries) {
    return;
  }

  let isSentryLoaded = false;

  const onFirstError = async (event: ErrorEvent) => {
    const Sentry = await import("@sentry/browser");
    !isSentryLoaded &&
      Sentry.init({
        dsn: "https://675c23ad028a4ffea9470955c727b8a1@o179954.ingest.sentry.io/4504711422935040",
        autoSessionTracking: false,
      });

    isSentryLoaded = true;
    window.removeEventListener("error", onFirstError);

    Sentry.captureException(event.error);
  };

  window.addEventListener("error", onFirstError);
};
